import { Fade, Modal } from '@material-ui/core';
import React, { memo } from 'react';
import { getRandomTip } from '../../data/tips';
import { Loader } from '@mantine/core';
// import Logo from '../shared/Logo';

const dataTestId = 'loading-screen';

const LoadingScreen = () => (
  <Modal data-testid={dataTestId} open hideBackdrop>
    <Fade in>
      <div className="w-screen h-screen flex justify-center items-center outline-none transition duration-1000">
        <div className="flex flex-col items-center">
          <div id='title' className="fade-in-fwd">
            <span>
              <img className="w-[348px] mb-2 mx-auto" alt="logo" src="https://boardsi.com/wp-content/uploads/2021/03/boardsi-logo-flat-1200-1.png" />
            </span>
              <span>
                <div className="mx-auto inline-block pl-2">
                <Loader size="xl" variant="bars" />
                </div>
              </span>
          </div>
        </div>
      </div>
    </Fade>
  </Modal>
);

export default memo(LoadingScreen);
export { dataTestId };
